import { ref } from 'vue'
import { validateCaptcha, validateMobile, validatePassword } from './rules'

// 拦截忘记密码规则接口
interface IForgetRules {
  mobile: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  captcha: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  newpassword: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
}

// 拦截忘记密码规则
export const forgetRules = ref<IForgetRules>({
  mobile: [
    {
      min: 11,
      max: 11,
      message: '只支持11位手机号',
      trigger: 'blur'
    },
    {
      validator: validateMobile,
      trigger: 'blur'
    }
  ],
  captcha: [
    {
      min: 4,
      max: 8,
      message: '验证码长度必须在4到8个字符',
      trigger: 'blur'
    },
    {
      validator: validateCaptcha,
      trigger: 'blur'
    }
  ],
  newpassword: [
    {
      min: 6,
      max: 30,
      message: '密码长度必须在6到30个字符',
      trigger: 'blur'
    },
    {
      validator: validatePassword,
      trigger: 'blur'
    }
  ]
})
