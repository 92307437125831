<template>
  <div class="Forget">
    <el-row>
      <el-col :xs="{span: 22, offset: 1}" :sm="{span: 12, offset: 6}" :md="{span: 8, offset: 8}" :lg="{span: 6, offset: 9}" :xl="{span: 4, offset: 10}">
        <h3 class="title">
          <router-link :to="{ name: 'Index' }">
            {{ config.name }}
          </router-link>
        </h3>
        <h4 class="desc">找回您的账号</h4>
        <transition name="el-fade-in" mode="out-in">
          <el-form ref="forgetForm" status-icon :model="forgetForms" :rules="forgetRules">
            <el-form-item label="手机号" prop="mobile">
              <el-input placeholder="请输入手机号" clearable v-model="forgetForms.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input placeholder="请输入验证码" v-model="forgetForms.captcha" auto-complete="off">
                <template #append>
                  <el-button @click="handleSms" :loading="sendSms">获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="newpassword">
              <el-input placeholder="请输入新的登录密码" show-password v-model="forgetForms.newpassword" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item class="button-group">
              <el-button type="primary" @click="handleForget">提交</el-button>
              <el-button @click="toLogin">
                返回登录
              </el-button>
            </el-form-item>
          </el-form>
        </transition>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { forgetRules } from './rules/forget-rules'
import { IResponse } from '../common/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

declare let globalConfig:any

interface IForgetForms {
  mobile: string;
  captcha: string;
  newpassword: string;
}

export default defineComponent({
  name: 'Forget',
  setup () {
    const { proxy } : any = getCurrentInstance()
    const config = globalConfig
    const sendSms = ref(false)
    const router = useRouter()

    // 注册表单字段
    const forgetForms = ref<IForgetForms>({
      mobile: '',
      captcha: '',
      newpassword: ''
    })

    const toLogin = (): void => {
      router.push({ name: 'Login' })
    }

    // 重置密码操作
    const handleForget = (): void => {
      proxy.$refs.forgetForm.validate((valid: boolean) => {
        if (valid) {
          proxy.$axios.post('/user/resetpwd', forgetForms.value)
            .then((res: IResponse) => {
              if (res.code === 1) {
                ElMessage.success({
                  message: res.msg,
                  type: 'success'
                })
                setTimeout(() => {
                  const routerUrl = router.resolve({ path: '/login' })
                  location.href = routerUrl.href
                }, 200)
              } else {
                ElMessage.warning({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = forgetForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'resetpwd'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    return {
      forgetForms,
      forgetRules,
      handleForget,
      handleSms,
      sendSms,
      toLogin,
      config
    }
  }
})
</script>

<style lang="scss" scoped>
  .Forget {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #409EFF;
    .el-row {
      width: 100%;
    }
    .el-col {
      max-width: 360px;
      min-width: 240px;
      margin: 0 auto;
      padding: 30px 24px;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(16,16,17,.02), 0 3.4px 8px rgba(16,16,17,.007), 0 12px 30px rgba(16,16,17,.003);
      animation: fade-in 0.5s;
      background: rgba(255, 255, 255);
      .title {
        color: #303133;
        line-height: 1.8;
      }
      .desc {
        color: #303133;
        font-weight: 400;
        line-height: 2.4;
      }
      .other {
        display: flex;
        justify-content: center;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 320px) {
    .Forget {
      .el-button {
        display: block;
        width: 100%;
      }
      .other {
        flex-direction: column;
        .el-button {
          margin-top: 10px;
        }
      }
      .el-button+.el-button {
        margin-left: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-divider--horizontal {
        margin: 12px 0;
      }
      .submit.el-button {
        margin-top: 12px;
      }
    }
  }
</style>
