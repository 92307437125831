
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { forgetRules } from './rules/forget-rules'
import { IResponse } from '../common/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

declare let globalConfig:any

interface IForgetForms {
  mobile: string;
  captcha: string;
  newpassword: string;
}

export default defineComponent({
  name: 'Forget',
  setup () {
    const { proxy } : any = getCurrentInstance()
    const config = globalConfig
    const sendSms = ref(false)
    const router = useRouter()

    // 注册表单字段
    const forgetForms = ref<IForgetForms>({
      mobile: '',
      captcha: '',
      newpassword: ''
    })

    const toLogin = (): void => {
      router.push({ name: 'Login' })
    }

    // 重置密码操作
    const handleForget = (): void => {
      proxy.$refs.forgetForm.validate((valid: boolean) => {
        if (valid) {
          proxy.$axios.post('/user/resetpwd', forgetForms.value)
            .then((res: IResponse) => {
              if (res.code === 1) {
                ElMessage.success({
                  message: res.msg,
                  type: 'success'
                })
                setTimeout(() => {
                  const routerUrl = router.resolve({ path: '/login' })
                  location.href = routerUrl.href
                }, 200)
              } else {
                ElMessage.warning({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = forgetForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'resetpwd'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    return {
      forgetForms,
      forgetRules,
      handleForget,
      handleSms,
      sendSms,
      toLogin,
      config
    }
  }
})
